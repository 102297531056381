.head-banner {
    width: 100vw;
    height: 38vh;
    position: relative;
}

.banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.head-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0 0 10px #000;
}

.back-btn {
    position: absolute;
    left: 10px;
    top: 10px;
}

.menu-img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    border-radius: 10px
}

.menu-name {
    font-size: 14px;
    font-weight: bold;
    padding: 0;
}

.menu-desc {
    font-size: 13px;
    padding: 0;
}

.menu-price, .menu-tag {
    font-size: 13px;
    font-weight: bold;
    padding: 0;
}

@media screen and (max-width: 992px) {
    .head-banner {
        height: 40vh;
    }

    .menu-img {
        height: 90px;
        width: 90px;
    }
}

@media screen and (max-width: 766px) {
    .head-banner {
        height: 35vh;
    }

    .menu-img {
        height: 90px;
        width: 90px;
        object-fit: cover;
    }
}

@media screen and (max-width: 500px) {
    .head-banner {
        height: 30vh;
    }

    .menu-img {
        height: 90px;
        width: 90px;
        object-fit: cover;
    }
}