.head-banner {
    width: 100vw;
    height: 38vh;
    position: relative;
}

.banner-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.logo-div {
    height: 200px;
    width: 200px;
    border-radius: 50%;
    background-color: #fff;
    padding: 25px;
    position: absolute;
    bottom: -200px;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 10px 13px 28px -16px rgba(0, 0, 0, 0.14);
    -webkit-box-shadow: 10px 13px 28px -16px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 10px 13px 28px -16px rgba(0, 0, 0, 0.14);
}

.hotel-name-section {
    margin-top: 115px;
}

.head-name {
    font-size: 30px;
    font-weight: bold;
    color: #000;
}

.hotem-loc {
    font-size: 20px;
    color: #000;
}

/* mobile */

@media screen and (max-width: 1200px) {
    .logo-div {
        height: 200px;
        width: 200px;
        bottom: -200px;
    }
}

@media screen and (max-width: 992px) {
    .logo-div {
        height: 180px;
        width: 180px;
        bottom: -180px;
    }
    .head-banner {
        height: 40vh;
    }
    .hotel-name-section {
        margin-top: 100px;
    }
    .head-name {
        font-size: 28px;
    }
}

@media screen and (max-width: 766px) {
    .logo-div {
        height: 150px;
        width: 150px;
        bottom: -150px;
    }
    .head-banner {
        height: 35vh;
    }
    .hotel-name-section {
        margin-top: 75px;
    }
    .head-name {
        font-size: 25px;
    }
}

@media screen and (max-width: 500px) {
    .head-banner {
        height: 30vh;
    }
    .head-name {
        font-size: 22px;
    }
}