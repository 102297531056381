/* Font */

.poppins-regular {
  font-family: 'Poppins Regular';
}

.poppins-bold {
  font-family: 'Poppins Bold';
}

.poppins-semibold {
  font-family: 'Poppins SemiBold';
}